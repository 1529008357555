import React, { useState } from 'react'
import cls from './ContentVideos.module.scss'
import Video from '../Video/Video'
import ContentVideo1 from '../../assets/video/video-1.mp4'
import ContentVideo2 from '../../assets/video/video-2.mp4'
import ContentVideo3 from '../../assets/video/video-3.mp4'
import ContentVideo4 from '../../assets/video/video-4.mp4'
import ContentImg1 from '../../assets/img/video-to-img-1.png'
import ContentImg2 from '../../assets/img/video-to-img-2.png'
import ContentImg3 from '../../assets/img/video-to-img-3.png'
import ContentImg4 from '../../assets/img/video-to-img-4.png'
import useWindowWidth from '../../hooks/hooks'
import { useTranslation } from 'react-i18next'

const ContentVideos = () => {
    const [checkWidth, setCheckWidth] = useState(useWindowWidth())
    const [playVideoSafari, setPlayVideoSafari] = useState(false);
    const [t] = useTranslation();

    return (
        <div className={cls.contentVideos}>
            <div className={cls.contentVideos__block}>
                <h3 className={cls['contentVideos__block-title']}>{t('content.text_1')}</h3>
                <div className={cls['contentVideos__block-content']}>
                {checkWidth < 860 ?
                    <img src={ContentImg1} alt='activity'/>
                    :
                    <Video  video={ContentVideo1} videoFormat={'mp4'} bool={playVideoSafari} setBool={setPlayVideoSafari}/>
                }
                </div>
                <div className={cls['contentVideos__block-bottom']}>
                    <p>{t('content.subtitle_1')}</p>
                    <div><a href='https://pay.letknow.com/en/registration' target='_black'><button>{t('moreThan.btn')}</button></a></div>
                </div>
            </div>
            <div className={cls.contentVideos__block}>
                <h3 className={cls['contentVideos__block-title']}>{t('content.text_2')}</h3>
                <div className={cls['contentVideos__block-content']}>
                {checkWidth < 860 ?
                    <img src={ContentImg2} alt='activity'/>
                    :
                    <Video  video={ContentVideo2} videoFormat={'mp4'} bool={playVideoSafari} setBool={setPlayVideoSafari}/>
                }
                </div>
            </div>
            <div className={cls.contentVideos__block}>
                <h3 className={cls['contentVideos__block-title']}>{t('content.text_3')}</h3>
                <div className={cls['contentVideos__block-content']}>
                {checkWidth < 860 ?
                    <img src={ContentImg3} alt='Create new user'/>
                    :
                    <Video  video={ContentVideo3} videoFormat={'mov'} bool={playVideoSafari} setBool={setPlayVideoSafari}/>
                }
                </div>
            </div>
            <div className={cls.contentVideos__block}>
                <h3 className={cls['contentVideos__block-title']}>{t('content.text_4')}</h3>
                <div className={cls['contentVideos__block-content']}>
                {checkWidth < 860 ?
                    <img src={ContentImg4} alt='activity'/>
                    :
                    <Video  video={ContentVideo4} videoFormat={'mov'} bool={playVideoSafari} setBool={setPlayVideoSafari}/>
                }
                </div>
                <div className={cls['contentVideos__block-bottom']}>
                    <p>{t('content.subtitle_4')}</p>
                    <div><a href='https://pay.letknow.com/en/registration' target='_black'><button>{t('moreThan.btn')}</button></a></div>
                </div>
            </div>
        </div>
  )
}

export default ContentVideos