const Languages = {
    en: 'en',
    el: 'el',
    ja: 'ja',
    ko: 'ko',
    cmn: 'cmn',
    fr: 'fr',
    de: 'de',
    it: 'it',
    pt: 'pt',
    es: 'es',
    bg: 'bg',
    in: 'in',
}

export const LanguageList = [
    {value: Languages.en, content: 'English'},
    {value: Languages.el, content: 'Greek'},
    {value: Languages.ja, content: 'Japanese'},
    {value: Languages.ko, content: 'Korean'},
    {value: Languages.cmn, content: 'Mandarin'},
    {value: Languages.fr, content: 'French'},
    {value: Languages.de, content: 'German'},
    {value: Languages.it, content: 'Italian'},
    {value: Languages.pt, content: 'Portuguese'},
    {value: Languages.es, content: 'Spanish'},
    {value: Languages.bg, content: 'Bulgarian'},
    {value: Languages.in, content: 'Indian'},
]