import React, { useEffect, useRef, useState } from "react";
import cls from "./GoDeeper.module.scss";
import icon from "../../assets/icon/icon-goDeeper.svg";
import goDeeperVideo from "../../assets/video/goDeeper.mp4";
import iconPlay from "../../assets/icon/icon-play.svg";
import Presentation from "../../assets/pdf/Presentation.pdf";
import { useTranslation } from "react-i18next";

const GoDeeper = () => {
  const videoRef = useRef(null);
  const [checkPlay, setCheckPlay]= useState(false)
  const [t] = useTranslation();

  const playVideo = (i) => {
    if (i === 'play') {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Ошибка при запуске видео:", error);
        });
        setCheckPlay(true)
      }
    } else {
        if (videoRef.current) {
          videoRef.current.pause();
          setCheckPlay(false)
        }
    }
  }
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (videoRef.current && !playVideo) {
            videoRef.current.play().catch((error) => {
              console.error('Ошибка при запуске видео:', error);
            });
            setCheckPlay(true);
          }
          observer.unobserve(videoRef.current);
        }
      });
    }, options);

    observer.observe(videoRef.current);

    return () => {
      observer.unobserve(videoRef.current);
    };
  }, [playVideo])

  return (
    <div className={cls.goDeeper}>
      <h2 className={cls.goDeeper__title}>{t("deeper.title")}</h2>
      <div className={cls.goDeeper__content}>
        <div className={cls.goDeeper__left}>
          <p>{t("deeper.subtitle")}</p>
          <div>
            <a href={Presentation} target="_blank">
            {t("deeper.btn")} <img src={icon} alt="icon" />
            </a>
          </div>
        </div>
        <div className={cls.goDeeper__right}>
          <div className={cls.videos}>
            <video
              ref={videoRef}
              width="100%"
              height="auto"
              playsInline
              loop
              muted
              loading="lazy"
            >
              <source src={goDeeperVideo} type="video/mp4" />
            </video>
          </div>
         {!checkPlay ?
          <div className={cls.play}>
            <img src={iconPlay} alt="icon play" onClick={() => playVideo('play')}/>
          </div>
          :
          <div className={cls.play} onClick={() => playVideo('pause')}>
          </div>
         }
        </div>
      </div>
    </div>
  );
};

export default GoDeeper;
