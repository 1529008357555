import MainScreen from "../components/MainScreen/MainScreen";
import cls from "./Home.module.scss";
import invoiceGif from "../assets/video/invoice.mp4";
import Video from "../components/Video/Video";
import MoreThan from "../components/MoreThan/MoreThan";
import Deposit from "../components/Deposit/Deposit";
import CryptoIcon from "../components/RunningLine/CryptoIcon";
import Benefits from "../components/Benefits/Benefits";
import ContentVideos from "../components/ContentVideos/ContentVideos";
import KeyFeatures from "../components/KeyFeatures/KeyFeatures";
import Statistics from "../components/Statistics/Statistics";
import Exchange from "../components/Exchange/Exchange";
import Location from "../components/Locations/Locations";
import GoDeeper from "../components/GoDeeper/GoDeeper";
import Discuss from "../components/Discuss/Discuss";
import JoinToday from "../components/JoinToday/JoinToday";

const Home = () => {
  return (
    <>
      <div className={cls.home__mainScreen}>
        <MainScreen />
      </div>
      <div className={cls.home}>
        <div id="invoice" className={cls.home__invoice}>
            <Video video={invoiceGif} videoFormat={'.mp4'}/>
        </div>
        <div className={cls.home__moreThan}>
          <MoreThan/>
        </div>
        <div id="deposit" className={cls.home__deposit}>
          <Deposit />
        </div>
        <div className={cls.home__cryptoIcon}>
          <CryptoIcon />
        </div>
        <div className={cls.home__benefits}>
          <Benefits />
        </div> 
        <div className={cls.home__contentVideos}>
          <ContentVideos />
        </div>
        <div id="features" className={cls.home__keyFeatures}>
          <KeyFeatures />
        </div>
      </div>
      <div className={cls.home__statistics}>
        <Statistics />
      </div>
      <div className={cls.home__exchange}>
        <Exchange />
      </div>
      <div className={cls.home__location}>
        <Location />
      </div>
      <div className={cls.home__goDeeper}>
        <GoDeeper />
      </div>
      <div id='contact' className={cls.home__discuss}>
        <Discuss />
      </div>
      <div id="download" className={cls.home__joinToday}>
        <JoinToday />
      </div>
    </>
  );
};

export default Home;
