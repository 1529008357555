import React from 'react'
import cls from './Benefits.module.scss'
import iconT from '../../assets/cryptoIcons/5.png'
import iconB from '../../assets/cryptoIcons/6.png'
import iconE from '../../assets/cryptoIcons/7.png'
import iconU from '../../assets/cryptoIcons/30.png'
import iconS from '../../assets/cryptoIcons/34.svg'
import iconD from '../../assets/cryptoIcons/35.svg'
import { useTranslation } from 'react-i18next'

const Benefits = () => {
    const [t] = useTranslation();

    return (
        <div className={cls.benefits}>
            <h2 className={cls.benefits__title}>{t("benefits.title")}</h2>
            <div className={cls.benefits__content}>
                <div className={cls.benefits__info}>
                    <p className={cls['benefits__content-subTitle']}>{t("benefits.subtitle")}</p>
                    <div className={cls.benefits__contentBlock}>
                        <div>
                            <p>{t("benefits.block_1")}</p>
                            <img src={iconT} alt='crypto icon'/>
                        </div>
                        <div>
                            <p>Crypto to Crypto</p>
                            <img src={iconB} alt='crypto icon'/>
                            <img src={iconE} alt='crypto icon'/>
                        </div>
                        <div>
                            <p>EUR</p>
                            <img src={iconU} alt='crypto icon'/>
                        </div>
                        <div>
                            <p>USD</p>
                            <img src={iconS} alt='crypto icon'/>
                        </div>
                        <div>
                            <p>CHF</p>
                            <img src={iconD} alt='crypto icon'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits