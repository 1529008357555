import React from "react";
import cls from "./Deposit.module.scss";
import Video from "../Video/Video";
import videoPlanet from "../../assets/video/1920.mp4";
import { useTranslation } from "react-i18next";

const Deposit = () => {
  const [t] = useTranslation();
  return (
    <div className={cls.deposit}>
      <h2 className={cls.deposit__title}>
        {t("deposits.title")}
      </h2>
      <div className={cls.deposit__content}>
        <div className={cls.deposit__left}>
          <p className={cls["deposit__left-des"]}>
            {t("deposits.subtitle_1")} <br />
            {t("deposits.subtitle_2")}
          </p>
          <p className={cls["deposit__left-text-none"]}>{t("deposits.text")}</p>
          <p className={cls["deposit__left-count"]}>{t("deposits.count")}</p>
          <p className={cls["deposit__left-text"]}>{t("deposits.text_1")}{t("deposits.text_2")}</p>
        </div>
      </div>
      <div className={cls.deposit__bg}>
        <Video video={videoPlanet} videoFormat={"mp4"} />
      </div>
    </div>
  );
};

export default Deposit;
