import Marquee from "react-double-marquee";
import cls from "./RunningLine.module.scss";

export default function RunningLineText({ content, info, position }) {
  
  return (
    <div
    className={cls["running-line"]}
    style={{
      whiteSpace: 'nowrap',
    }}
  >
      <Marquee
        className={cls["running-line__part"]}
        direction={position}
        autoFill={true}
      >
        {content.map((item, index) => (
          <span key={`left-${index + 100}`}>
              {item}
          </span>
        ))}
      </Marquee>
    </div>
  );
}