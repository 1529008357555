import React, { useEffect, useRef } from 'react';
import cls from "./MainScreen.module.scss";
import mainGif from "../../assets/video/-e3cf-48cb-a5a8-2b0f0ad8b8832.mp4";
import RunningLineText from '../RunningLine/RunningLineText';
import { useTranslation } from 'react-i18next';

const MainScreen = () => {
  const videoRef = useRef();
  const [t] = useTranslation();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error('Ошибка при запуске видео:', error);
      });
    }
    
  }, []);
  const LineTextTop = [
    t('main.right.text_1'),
    t('main.right.text_2'),
    t('main.right.text_3'),
    t('main.right.text_4'),
    t('main.right.text_5')
  ]
  const LineTextBottom = [
    t('main.right.text_6'),
    t('main.right.text_7'),
    t('main.right.text_6'),
    t('main.right.text_8')
  ]
  return (
    <div className={cls.container}>
      <div className={cls.mainScreen__gif}>
      <video className={cls.videos} ref={videoRef} width="100%" height="auto" playsInline loop muted loading="lazy">
          <source src={mainGif} type="video/mp4"/>
        </video>
      </div>
      <div className={cls.mainScreen}>
        <p className={cls.mainScreen__title}>
          {t("main.begin")}
        </p>
        <div className={cls.mainScreen__middle}>
            <div>
            <div>
              <h2>{t("main.cross")}</h2>
              <div className={cls['mainScreen__middle-btn']}><a target='_blank' href='https://lk-payment.netlify.app/' >View Demo</a></div>
            </div>
            <ul className={cls["mainScreen__middle-list"]}>
                <li>{t("main.right.text_1")}</li>
                <li>{t("main.right.text_2")}</li>
                <li>{t("main.right.text_3")}</li>
                <li>{t("main.right.text_4")}</li>
                <li>{t("main.right.text_5")}</li>
                <li>{t("main.right.text_6")}</li>
                <li>{t("main.right.text_7")}</li>
                <li>{t("main.right.text_8")}</li>
            </ul>
            <div className={cls["mainScreen__line"]}>
              <div className={cls["mainScreen__line-bg"]}>
                <div></div>
                <div></div>
              </div>
                <RunningLineText content={LineTextTop} info="text" position={'left'}/>
                <RunningLineText content={LineTextBottom} info="text" position={'right'}/>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
