import React, { useEffect, useRef, useState } from 'react';
import cls from "./Video.module.scss";

const Video = ({ video, videoFormat }) => {
  const videoRef = useRef();
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !playVideo) {
          if (videoRef.current) {
            videoRef.current.play().catch((error) => {
              console.error('Ошибка при запуске видео:', error);
            });
            setPlayVideo(true);
          }
        } else {
          if (videoRef.current) {
            videoRef.current.pause();
            setPlayVideo(false);
          }
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [playVideo]);

  return (
    <video className={cls.videos} ref={videoRef} width="100%" height="auto" playsInline loop muted loading="lazy">
      <source src={video} type="video/mp4"/>
      Ваш браузер не поддерживает видео в формате: {videoFormat}
    </video>
  );
};

export default Video;
