import React, { useEffect, useState } from "react";
import cls from "./MoreThan.module.scss";
import iconCrypto from "../../assets/icon/icon-crypto.svg";
import iconBinance from "../../assets/icon/icon-binance.png";
import iconVisa from "../../assets/icon/icon-credit-card.png";
import { useTranslation } from "react-i18next";

const countries = [
  {
    title: "Argentina ",
    block: [
      { icon: require('../../assets/icon/icon-khipu.png'), text: "Khipu" },
      { icon: require('../../assets/icon/icon-rapipago.png'), text: "Rapipago",},
      { icon: require('../../assets/icon/icon-bank.png'),text: "Bank transfer",},
    ],
  },
  {
    title: "Brazil",
    block: [
      {
        icon: require('../../assets/icon/icon-credit-card.png'),
        text: "Credit card",
      },
      { icon: require('../../assets/icon/icon-boleto.png'), text: "Boleto" },
      { icon: require('../../assets/icon/icon-wallet.png'), text: "Wallet" },
      { icon: require('../../assets/icon/icon-PIX.png'), text: "PIX" },
      {
        icon: require('../../assets/icon/icon-deposit-express.png'),
        text: "Deposit Express",
      },
    ],
  },
  {
    title: "Mexico",
    block: [
      { icon: require('../../assets/icon/icon-SPEI.png'), text: "SPEI" },
      { icon: require('../../assets/icon/icon-OXXOpay.png'), text: "OXXOpay", },
      { icon: require('../../assets/icon/icon-CoDi.png'), text: "CoDi" },
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
      { icon: require('../../assets/icon/icon-wallet.png'), text: "Wallet" },
    ],
  },
  {
    title: "Colombia",
    block: [
      { icon: require('../../assets/icon/icon-PSE.png'), text: "PSE" },
      { icon: require('../../assets/icon/icon-wallet.png'), text: "eWallet" },
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
      { icon: require('../../assets/icon/icon-percent.png'), text: "Vouchers" },
    ],
  },
  {
    title: "Peru",
    block: [
      {
        icon: require('../../assets/icon/icon-bank.png'),
        text: "Bank transfer",
      },
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
    ],
  },
  {
    title: "Ecuador",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Bank transfer", },
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
    ],
  },
  {
    title: "Costa Rica",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Bank transfer", },
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
    ],
  },
  {
    title: "Guatemala",
    block: [
      { icon: require('../../assets/icon/icon-cash.png'), text: "Cash" },
    ],
  },
  {
    title: "Bolivia",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Bank transfer", },
    ],
  },
  {
    title: "Uruguay",
    block: [
      {
        icon: require('../../assets/icon/icon-bank.png'),
        text: "Bank transfer",
      },
    ],
  },
  {
    title: "Paraguay",
    block: [
      {
        icon: require('../../assets/icon/icon-khipu.png'),
        text: "Bank transfer",
      },
    ],
  },
  {
    title: "China",
    block: [
      { icon: require('../../assets/icon/icon-union.png'), text: "P2P market", },
      { icon: require('../../assets/icon/icon-chat.png'), text: "WeChat", },
    ],
  },
  {
    title: "Laos",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Local bank transfer (QR code)", },
    ],
  },
  {
    title: "Myanmar",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Local bank transfer (QR code)", },
    ],
  },
  {
    title: "Cambodia",
    block: [
      { icon: require('../../assets/icon/icon-bank.png'), text: "Local bank transfers", },
    ],
  },
  {
    title: "Malaysia",
    block: [
      { icon: require('../../assets/icon/icon-affinbank.png'), text: "" },
      { icon: require('../../assets/icon/icon-alliance-bank.png'), text: "" },
      { icon: require('../../assets/icon/icon-ocbc.png'), text: "" },
      { icon: require('../../assets/icon/icon-bankislam.png'), text: "" },
      { icon: require('../../assets/icon/icon-hsbc.png'), text: "" },
      { icon: require('../../assets/icon/icon-cimb.png'), text: "" },
      { icon: require('../../assets/icon/icon-maybank.png'), text: "" },
      { icon: require('../../assets/icon/icon_public_bank 1.png'), text: "" },
      { icon: require('../../assets/icon/icon-hongleon.png'), text: "" },
      { icon: require('../../assets/icon/icon-bsn.png'), text: "" },
      { icon: require('../../assets/icon/icon-rhb-bank.png'), text: "" },
      { icon: require('../../assets/icon/icon-ambank.png'), text: "" },
    ],
  },
  {
    title: "Vietnam",
    block: [
      { icon: require('../../assets/icon/icon-viet.png'),  text: "VietQRPay",},
      { icon: require('../../assets/icon/icon-virttel.png'), text: "VietQR Viettel Pay" },
      { icon: require('../../assets/icon/icon-zaloPay.png'), text: "ZaloPay" },
    ],
  },
  {
    title: "India",
    block: [
      { icon: require('../../assets/icon/icon-india-3.png'), text: "HDFC" },
      { icon: require('../../assets/icon/icon-india-6.png'), text: "Unified" },
    ],
  },
  {
    title: "Philippines",
    block: [
      { icon: require('../../assets/icon/icon-gCash.png'), text: "GCash", },
      { icon: require('../../assets/icon/icon-metrobank.png'), text: "Metrobank", },
      { icon: require('../../assets/icon/icon-union-bank.png'), text: "Union Bank", },
      { icon: require('../../assets/icon/icon-RCBC.png'), text: "RCBC", },
      { icon: require('../../assets/icon/icon-eastwest.png'), text: "", },
      { icon: require('../../assets/icon/icon-BPI.png'), text: "BPI", },
    ],
  },
  {
    title: "Indonesia",
    block: [
      { icon: require('../../assets/icon/icon-web.png'), text: "Online banking", },
      { icon: require('../../assets/icon/icon-dana.png'), text: "", },
      { icon: require('../../assets/icon/icon-bca.png'), text: "", },
      { icon: require('../../assets/icon/icon-bank-bri.png'), text: "", },
      { icon: require('../../assets/icon/icon-CIMB-2.png'), text: "", },
      { icon: require('../../assets/icon/icon-link-aja.png'), text: "", },
      { icon: require('../../assets/icon/icon-BBNI.png'), text: "", },
      { icon: require('../../assets/icon/icon-mandiri_logo.png'), text: "", },
      { icon: require('../../assets/icon/icon-panin_Bank.png'), text: "", },
      { icon: require('../../assets/icon/icon-shopee.png'), text: "", },
      { icon: require('../../assets/icon/icon-ris.png'), text: "", },
      { icon: require('../../assets/icon/icon-goPay.png'), text: "", },
    ],
  },
];

const MoreThan = () => {
  const [checkMoreThan, setCheckMoreThan] = useState(false);
  const [t] = useTranslation();

  return (
    <div className={cls.moreThan}>
      <div className={cls.moreThan__left}>
      <div className={cls['moreThan__left-bg']}></div>   
        <p className={cls["moreThan__left-title"]}>{t('moreThan.title')}</p>
        <p className={cls["moreThan__left-count"]}>{t('moreThan.count')}</p>
        <p className={cls["moreThan__left-subTitle"]}>{t('moreThan.subtitle')}</p>
        <p className={cls["moreThan__left-info"]}>
        {t('moreThan.text')}
        </p>
        <a href="https://pay.letknow.com/en/registration" target="_blank" ><button className={cls["moreThan__left-btn"]}>{t('moreThan.btn')}</button></a>
      </div>
      <div className={cls.moreThan__right}>
        <ul
          className={
            cls[
              !checkMoreThan
                ? "moreThan__right-list"
                : "moreThan__right-list-active"
            ]
          }
        >
          <li>
            <h3>{t('moreThan.right')}</h3>
            <div className={cls["moreThan__right-list-block"]}>
              <div>
                <img src={iconCrypto} alt="icons crypto" />
                <p>Crypto</p>
              </div>
              <div>
                <img src={iconBinance} alt="icons crypto" />
                <p>BinancePay</p>
              </div>
              <div>
                <img src={iconVisa} alt="icons crypto" />
                <p>Bank Card</p>
              </div>
            </div>
          </li>
          {countries.map((item, index) => {
            return (
              <li
                key={index * Math.floor(Math.random() * (1000 - 100 + 1)) + 100}
              >
                <h3>{item.title}</h3>
                <div className={cls["moreThan__right-list-block"]}>
                  {item.block.map((elem, index) => {
                    return (
                      <div
                        key={
                          index +
                          Math.floor(Math.random() * (1000 - 100 + 1)) +
                          100 + index
                        }
                      >
                        <img src={elem.icon} alt={elem.text} />
                        <p>{elem.text}</p>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
        <button
          className={cls["moreThan__right-btn"]}
          type="button"
          onClick={() => setCheckMoreThan(!checkMoreThan)}
        >
          {!checkMoreThan ? t('moreThan.btnRight') : t('moreThan.HIDE')}
        </button>
      </div>
    </div>
  );
};

export default MoreThan;
