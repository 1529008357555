import React from "react";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <div className={styles.emptyBlock}></div>
      <h1>404 - Страница не найдена</h1>
    </div>
  );
};

export default NotFound;
