import React, { useEffect, useState } from 'react'
import cls from './Statistics.module.scss'
import imageContent1 from '../../assets/img/statistics-light.png'
import imageContent2 from '../../assets/img/statistics-dark.png'
import imageContent3 from '../../assets/img/statistics-carbon.png'
import iconMode1 from '../../assets/icon/light-mode.svg'
import iconMode2 from '../../assets/icon/dark-mode.png'
import iconMode3 from '../../assets/icon/carbon-mode.png'
import iconModeActive1 from '../../assets/icon/light-mode-active.png'
import iconModeActive2 from '../../assets/icon/dark-mode-active.png'
import iconModeActive3 from '../../assets/icon/carbon-mode-active.png'
import { useTranslation } from 'react-i18next'


const Statistics = () => {
  const [checkTheme, setCheckTheme] = useState('light')
  const [backgroundBlock, setBackgroundBlock] = useState('#040D20 ')
  const [colorText, setColorText] = useState('#FFF')
  const [t] = useTranslation();

  useEffect(() => {
    switch(checkTheme) {
      case "light": 
        setBackgroundBlock('linear-gradient(180deg, #E8E3F2 0%, #F7F8FD 100%, #FFF 100%)')
        setColorText('#000') 
        break
      case "carbon": 
        setBackgroundBlock('#0C0C0C')
        setColorText('#FFF')
        break
      default: 
        setBackgroundBlock('#040D20') 
        setColorText('#FFF')
        break
    }
  }, [checkTheme])

  return (
    <div className={cls["statistics"]} style={{background: backgroundBlock}}>
        <h2 className={cls["statistics__title"]} style={{color: colorText}}>{t("statistics.title")}</h2>
        <div className={cls["statistics__theme"]}>
            <div><p style={checkTheme === 'light' ? {color:  colorText} : {color:  '#8B7C8D'}} onClick={() => setCheckTheme('light')}><img src={checkTheme === 'light' ? iconModeActive1 : iconMode1} alt='icon theme'/>{t("statistics.theme_1")}</p></div>
            <div><p style={checkTheme === 'dark' ? {color:  colorText} : {color:  '#8B7C8D'}} onClick={() => setCheckTheme('dark')}><img src={checkTheme === 'dark' ? iconModeActive2 : iconMode2} alt='icon theme'/>{t("statistics.theme_2")}</p></div>
            <div><p style={checkTheme === 'carbon' ? {color:  colorText} : {color:  '#8B7C8D'}} onClick={() => setCheckTheme('carbon')}><img src={checkTheme === 'carbon' ? iconModeActive3 : iconMode3} alt='icon theme'/>{t("statistics.theme_3")}</p></div>
        </div>
        <div className={cls["statistics__img"]}>
          {checkTheme === 'light' && <img src={imageContent1} alt='content'/>}
          {checkTheme === 'dark' &&  <img src={imageContent2} alt='content'/>}
          {checkTheme === 'carbon' && <img src={imageContent3} alt='content'/>}
        </div>
        <p className={cls["statistics__text"]} style={{color: colorText}}>
          {t("statistics.subtitle")}
        </p>
        <div className={cls["statistics__btn"]}>
          <a href='https://pay.letknow.com/en/registration' target='_blank'><button >{t("statistics.btn")}</button></a>
        </div>
    </div>
  )
}

export default Statistics