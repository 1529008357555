import React, { Fragment, useEffect, useState } from 'react'
import cls from "./Language.module.scss";
import iconLang from "../../assets/icon/icon-lang.svg";
import i18n from 'i18next';
import { LanguageList } from '../../constants/language';
import { Menu } from '@headlessui/react';


const Language = () => {
  const [lang,  setLang] = useState('en')
  const [checkedLang,  setCheckedLang] = useState(false)
 
  const changeLanguages = (language) => {
    i18n.changeLanguage(language);
    setLang(language)
    if (language !== lang) {
      setCheckedLang(false)
    }
  };

  useEffect(() => {
  if (localStorage.getItem('i18nextLng')) {
    setLang(localStorage.getItem('i18nextLng'))
    i18n.changeLanguage(localStorage.getItem('i18nextLng'));
  } else {
    setLang('en')
  }
  }, [])

  return (
    <div className={cls.language}>
      <Menu>
      <Menu.Button onClick={() =>  setCheckedLang(!checkedLang)} ><p className={cls.language__selected} >{lang}</p> <img src={iconLang} alt='icons' className={checkedLang ? cls.iconActive : 'none'}/></Menu.Button>
      <Menu.Items >
      {LanguageList.map((item) => (
          <Menu.Item key={item.value} as={Fragment}>
            {({ active }) => (
              <li
                onClick={() => changeLanguages(item.value)}
                className={
                   active ? cls.active : i18n.language === item.value && ''
                }
              >
                 {item.content}
              </li>
            )}
          </Menu.Item>
        ))}
      {/* {LanguageList.map((item, index) => {
        return (
          
          <li
            key={index}
            className={i18n.language === item.value ? cls.active : 'none'} 
             
            tabIndex={index}
          >
            {item.content}
          </li>
        )
      })} */}
      </Menu.Items>
    </Menu>        
    </div>
  )
}

export default Language