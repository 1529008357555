import React from "react";
import cls from "./KeyFeatures.module.scss";
import { useTranslation } from "react-i18next";

const KeyFeatures = () => {
  const [t] = useTranslation();

  return (
    <div className={cls.keyFeatures}>
      <div className={cls.keyFeatures__bg}></div>
      <div className={cls.keyFeatures__content}>
        <h2 className={cls["keyFeatures__content-title"]}>
          {t("keyFeatures.title")}
        </h2>
        <div className={cls.keyFeatures__info}>
          <div className={cls["keyFeatures__info-block"]}>
            <p>{t("keyFeatures.text_1")}</p>
            <p>{t("keyFeatures.text_2")}</p>
            <p>{t("keyFeatures.text_3")}</p>
            <p>{t("keyFeatures.text_4")}</p>
            <p>{t("keyFeatures.text_5")}</p>
          </div>
          <div className={cls["keyFeatures__info-none"]}>
            <h2>{t("keyFeatures.title")}</h2>
            <div>
              <p>{t("keyFeatures.text_1")}</p>
              <p>{t("keyFeatures.text_2")}</p>
              <p>{t("keyFeatures.text_3")}</p>
              <p>{t("keyFeatures.text_4")}</p>
            </div>
            <p className={cls["keyFeatures__info-none-last"]}>
              {t("keyFeatures.text_5")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
