import React from 'react'
import cls from "./CryptoIcon.module.scss";
import RunningLine from './RunningLine';
import { useTranslation } from 'react-i18next';

const IconTop = [
  require('../../assets/cryptoIcons/1.png'),
  require('../../assets/cryptoIcons/2.png'),
  require('../../assets/cryptoIcons/3.png'),
  require('../../assets/cryptoIcons/4.png'),
  require('../../assets/cryptoIcons/5.png'),
  require('../../assets/cryptoIcons/6.png'),
  require('../../assets/cryptoIcons/7.png'),
  require('../../assets/cryptoIcons/8.png'),
  require('../../assets/cryptoIcons/9.png'),
  require('../../assets/cryptoIcons/10.png'),
  require('../../assets/cryptoIcons/11.png'),
  require('../../assets/cryptoIcons/12.png'),
  require('../../assets/cryptoIcons/13.png'),
  require('../../assets/cryptoIcons/14.png'),
  require('../../assets/cryptoIcons/15.png'),
];
const IconBottom = [
  require('../../assets/cryptoIcons/16.png'),
  require('../../assets/cryptoIcons/17.png'),
  require('../../assets/cryptoIcons/18.png'),
  require('../../assets/cryptoIcons/19.png'),
  require('../../assets/cryptoIcons/20.png'),
  require('../../assets/cryptoIcons/21.png'),
  require('../../assets/cryptoIcons/22.png'),
  require('../../assets/cryptoIcons/23.png'),
  require('../../assets/cryptoIcons/24.png'),
  require('../../assets/cryptoIcons/25.png'),
  require('../../assets/cryptoIcons/26.png'),
  require('../../assets/cryptoIcons/27.png'),
  require('../../assets/cryptoIcons/28.png'),
  require('../../assets/cryptoIcons/29.png'),
  require('../../assets/cryptoIcons/30.png'),
  require('../../assets/cryptoIcons/31.png'),
  require('../../assets/cryptoIcons/32.png'),
];

const CryptoIcon = () => {
  const [t] = useTranslation();
  return (
    <div className={cls.cryptoIcon}>
        <h2 className={cls.cryptoIcon__title}>{t('currencies')}</h2>
      <div className={cls.cryptoIcon__content}>
        <div className={cls.bg}></div>
          <RunningLine content={IconTop} info="img" position={'left'}/>
          <RunningLine content={IconBottom} info="img" position={'right'}/>
      </div>
    </div>
  )
}

export default CryptoIcon