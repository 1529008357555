import React from 'react'
import cls from './Footer.module.scss'
import iconInstagram from '../../assets/icon/icon-instagram.svg'
import iconLinkedin from '../../assets/icon/icon-linkedin.svg'
import iconTwitter from '../../assets/icon/icon-twitter.svg'
import privacy from '../../assets/pdf/privacy.pdf'
import termsConditions from '../../assets/pdf/termsConditions.pdf'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const [t] = useTranslation();

  return (
    <div className={cls.container}>
      <div className={cls.footer}>
      <div className={cls.footer__top}>
        <div className={cls.footer__social}>
          <a href='https://www.instagram.com/letknowpay/?hl=en' target="_blank"><img src={iconInstagram} alt='Instagram logo'/></a>
          <a href='https://ee.linkedin.com/company/letknow-pay' target="_blank"><img src={iconLinkedin} alt='Linkedin logo'/></a>
          <a href='https://twitter.com/i/flow/login?redirect_after_login=%2FletknowPay' target="_blank"><img src={iconTwitter} alt='Twitter logo'/></a>
        </div>
        <ul>
          <li><Link to={'#about'}>{t("footer.ul_1.text_1")}</Link></li>
          <li><Link to={'#keyFeatures'}>{t("footer.ul_1.text_2")}</Link></li>
          <li><Link to={'#exchange'}>{t("footer.ul_1.text_3")}</Link></li>
          <li><Link to={'#presentations'}>{t("footer.ul_1.text_4")}</Link></li>
          <li><Link to={'#ContactUs'}>{t("footer.ul_1.text_5")}</Link></li>
        </ul>
      </div>
      <div className={cls.footer__bottom}>
        <p>
        {t("footer.disclaimer")}
        </p>
        <ul>
          <li><a href={termsConditions} target="_blank">{t("footer.ul_2.text_1")}</a></li>
          <li><a href={privacy} target="_blank">{t("footer.ul_2.text_2")}</a></li>
          <li>{t("footer.ul_2.text_3")}</li>
        </ul>
      </div>
      </div>
    </div>
  )
}

export default Footer