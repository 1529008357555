import React, { useState } from 'react'
import cls from './Exchange.module.scss'
import exchangeImg from '../../assets/img/exchange-image.png'
import exchangeMobileImg from '../../assets/img/exchange-image-mobile.png'
import useWindowWidth from '../../hooks/hooks'
import { useTranslation } from 'react-i18next'

const Exchange = () => {
  const [checkWidth, setCheckWidth] = useState(useWindowWidth())
  const [t] = useTranslation();

  return (
    <div className={cls.exchange}>
    <div className={cls.exchange__left}>
        <h2>{t("exchange.title")}</h2>
        <p>{t("exchange.subtitle")}</p>
        <div className={cls['exchange__left-btn']}>
        <a href='https://pay.letknow.com/en/registration' target='_blank'><button>{t("moreThan.btn")}</button></a>
        </div>
    </div>
    <div className={cls.exchange__right}>
      {checkWidth < 769 ? 
        <img className={cls['exchange__left-img']} src={exchangeMobileImg} alt='exchange info'/>
        :
        <img className={cls['exchange__left-img']} src={exchangeImg} alt='exchange info'/>
      }
    </div>
    </div>
  )
}

export default Exchange 