import React, { useState } from "react";
import cls from "./Discuss.module.scss";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Discuss = () => {
  const [value, setValue] = useState({email: '', message: ''});
  const [error, setError] = useState({email: false, message: false});
  const [t] = useTranslation();

  const postData = {
    chat_id: -1002124192916,
    text: `email: ${value.email}\nmessage: ${value.message}`,
  };
  

  const createPost = () => {
    if (value.message) {
      axios.post('https://api.telegram.org/bot6960419410:AAHCSA4y5v5q1j2qjZjd99pSahuMQ22N6Y8/sendMessage', postData)
      .then(response => {
        console.log('Успешный ответ:', response.data);
      })
      .catch(error => {
        console.error('Ошибка при выполнении POST-запроса:', error);
      });
      setValue({email: '', message: ''})
    }
  }
  

  const handleSubmit = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!value.email.match(emailRegex)) {
      setError({...error, email: true})
    }

  };

  const handleChange = (e) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  return (
    <div className={cls.discuss}>
    <div className={cls.discuss__bg}></div>
      <div className={cls.discuss__content}>
        <h2>{t("discuss.title")}</h2>
        <h3>{t("discuss.subtitle")}</h3>
        <form className={cls.discuss__from} onSubmit={handleSubmit}>
         <div className={error.email ? cls['discuss__from-error'] : cls['discuss__from-inp']}>
          <input 
            name="email"
            type="email" 
            placeholder={t("discuss.email")}
            value={value.email}
            onChange={(e) => handleChange(e)}
          />
         </div>
         <div className={error.message ? cls['discuss__from-error'] : cls['discuss__from-inp']}>
          <input 
            name='message'
            type="text"
            placeholder={t("discuss.message")}
            value={value.message}
            onChange={(e) => handleChange(e)}
          />
         </div>
         <button 
           type="button"
           onClick={() => createPost()} 
         > {t("discuss.btn")}</button>
        </form>
      </div>
    </div>
  );
};

export default Discuss;
