import Marquee from "react-fast-marquee";
import cls from "./RunningLine.module.scss";


export default function RunningLine({ content, info, position }) {
  
  return (
    <div
    className={cls["running-line"]}
    style={{
      whiteSpace: 'nowrap',
    }}
  >
      <Marquee
        className={cls["running-line__part"]}
        direction={position}
        autoFill={true}
      >
        {content.map((item, index) => (
              <img key={`left-${index + 100}`}
                src={item.default ? item.default : item}
                alt="crypto icon"
                loading="lazy"
              />
        ))}
      </Marquee>
    </div>
  );
}