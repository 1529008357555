import React from 'react'
import cls from './Locations.module.scss'
import Video from '../Video/Video'
import imageContent1 from '../../assets/video/location.mp4'
import { useTranslation } from 'react-i18next'

const Locations = () => {
  const [t] = useTranslation();

  return (
    <div className={cls.location}>
      <div className={cls.location__left}>
          <h2>{t("location.title")}</h2>
          <div className={cls['location__left-btn']}>
          <p>{t("location.text")}</p>
          <a href='https://pay.letknow.com/en/registration' target='_blank'><button>{t("moreThan.btn")}</button></a>
          </div>
      </div>
      <div className={cls.location__right}>
        <div className={cls['location__right-container']}>
          
          <div className={cls['location__right-video']}><div className={cls['location__right-bg']}></div><Video video={imageContent1} videoFormat={'mp4'}/></div>
        </div>
      </div>
    </div>
  )
}

export default Locations