import React, { useState } from "react";
import cls from "./Header.module.scss";
import logo from "../../assets/icon/logo.svg"
import Language from "../Language/Language"

const Header = () => {
  const [burger, setBurger] = useState(false)

  return (
    <div className={cls.container}>
      <div className={cls.header}>
        <div className={cls.header__logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={cls.header__btns}>
          <div><Language /></div>
          <a href="https://pay.letknow.com/en/registration" target="_blank"><button className={cls.header__signUp}>Sign up</button></a>
          <a href="https://pay.letknow.com/en/login" target="_blank"><button className={cls.header__signIn}>Sign in</button></a>
        </div>
        <div className={cls['header__burger-block']}>
          <div className={cls.header__burger} onClick={() => setBurger(!burger)}>
            <div className={cls[burger ? 'header__burger-lineTop' : '']}></div>
            <div className={cls[burger ? 'header__burger-lineMiddle' : '']}></div>
            <div className={cls[burger ? 'header__burger-lineBottom' : '']}></div>
          </div>
        </div>
        {burger && 
        <div className={cls.header__mobile}>
          <div className={cls['header__mobile-top']}>
            <ul className={cls['header__mobile-list']}>
              <li><a href="#invoice" onClick={() => setBurger(!burger)}>Invoice Payments</a></li>
              <li><a href="#deposit" onClick={() => setBurger(!burger)}>Countries</a></li>
              <li><a href="#features" onClick={() => setBurger(!burger)}>Features</a></li>
              <li><a href="#contact" onClick={() => setBurger(!burger)}>Contact Us</a></li>
              <li><a href="#download" onClick={() => setBurger(!burger)}>Download App</a></li>
            </ul>
            <div className={cls['header__mobile-list-lang']}><Language /></div>
          </div>
          <div className={cls['header__mobile-bottom']}>
            <button className={cls.header__signUp}>Sign up</button>
            <button className={cls.header__signIn}>Sign in </button>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default Header;
