import React from 'react'
import cls from './JoinToday.module.scss'
import iconPlay from '../../assets/icon/icon-google-play.svg'
import iconApple from '../../assets/icon/icon-apple.svg'
import joinTodayBG from '../../assets/img/joinToday-bg.png'
import { useTranslation } from 'react-i18next'


const JoinToday = () => {
    const [t] = useTranslation();

    return (
        <div className={cls.joinToday}>
            <h2 className={cls.joinToday__title}>{t('JoinToday.title')}</h2>
            <div className={cls.joinToday__content}>
                <div className={cls.joinToday__info}>
                <p className={cls.joinToday__subTitle}>
                {t('JoinToday.subtitle')}
                </p>
                <div className={cls.joinToday__store}>
                    <a href='https://apps.apple.com/th/app/letknow-pay/id1637088258' className={cls['joinToday__store-appStore']} >
                        <img src={iconApple} alt='apple logo'/>
                        <div>
                            <p>{t('JoinToday.app.subtitle')}</p>
                            <h3>{t('JoinToday.app.title')}</h3>
                        </div>
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.app.lkpay_app&pli=1' className={cls['joinToday__store-play']}>
                        <img src={iconPlay} alt='google play logo'/>
                        <div>
                            <p>{t('JoinToday.google.subtitle')}</p>
                            <h3>{t('JoinToday.google.title')}</h3>
                        </div>
                    </a>
                </div>
                </div>
                <div className={cls.joinToday__img}>
                    <img src={joinTodayBG} alt='background'/>
                </div>
            </div>
        </div>
    )
}

export default JoinToday